<template>
    <div>
        <b-alert :show="not_found" variant="danger" fade>
            <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
                $t("COLOR.NOT_FOUND")
            }}</span>
        </b-alert>
        <!--begin::Card-->
        <div v-if="!not_found" class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">
                        {{
                            edit_mode
                                ? add_new
                                    ? $t("COLOR.NEW")
                                    : $t("COLOR.EDIT")
                                : $t("COLOR.VIEW")
                        }}
                    </h3>
                    <span
                        class="text-muted font-weight-bold font-size-sm mt-1"
                        >{{ color.name }}</span
                    >
                </div>
                <div v-if="edit_mode" class="card-toolbar">
                    <a
                        v-on:click="save()"
                        class="btn btn-primary px-15 mr-2"
                        id="color_save"
                        >{{ $t("SAVE") }}
                    </a>
                    <a v-on:click="cancel()" class="btn btn-secondary"
                        >{{ $t("CANCEL") }}
                    </a>
                </div>
                <div v-else class="card-toolbar">
                    <a v-on:click="edit()" class="btn btn-primary px-10"
                        >{{ $t("EDIT") }}
                    </a>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                    <form class="form" id="color_form">
                        <fieldset id="edit_wrapper" :disabled="!edit_mode">
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("COLOR.IMAGE") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <div
                                        class="image-input image-input-outline"
                                        id="kt_profile_avatar"
                                    >
                                        <div
                                            class="image-input-wrapper"
                                            :style="{
                                                backgroundImage: `url(${image})`,
                                            }"
                                        ></div>
                                        <label
                                            v-if="edit_mode"
                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="change"
                                        >
                                            <i
                                                class="fa fa-pen icon-sm text-muted"
                                            ></i>
                                            <input
                                                type="file"
                                                name="profile_avatar"
                                                accept=".png, .jpg, .jpeg"
                                                @change="changedImage($event)"
                                            />
                                        </label>
                                        <span
                                            v-if="edit_mode"
                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="remove"
                                            @click="clearImage()"
                                        >
                                            <i
                                                class="ki ki-bold-close icon-xs text-muted"
                                            ></i>
                                        </span>
                                    </div>
                                    <span
                                        v-if="edit_mode"
                                        class="form-text text-muted"
                                        >{{ $t("COLOR.IMAGE_FILETYPES") }}</span
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("COLOR.NAME") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="name"
                                        name="name"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="color.name"
                                    />
                                </div>
                            </div>

                            <!-- COLORCODE -->

                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("COLOR.CODE") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="code"
                                        name="code"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="color.code"
                                    />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("COLOR.WEIGHT") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <div
                                        class="input-group input-group-md input-group-solid"
                                    >
                                        <input
                                            ref="weight"
                                            name="weight"
                                            class="form-control form-control-md form-control-solid"
                                            type="text"
                                            v-model="color.weight"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text"
                                                >g</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("COLOR.YEAR") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="year"
                                        name="year"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="color.year"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("COLOR.ORIGIN") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="origin"
                                        name="origin"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="color.origin"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("COLOR.URL") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="url"
                                        name="url"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="color.url"
                                    />
                                </div>
                            </div>
                            <div class="form-group row" v-if="checkURL()">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                ></label>
                                <div class="col-lg-9 col-xl-6">
                                    <div
                                        class="btn btn-primary"
                                        v-on:click="setProductPageURL()"
                                        :class="edit_mode ? '' : 'disabled'"
                                    >
                                        {{
                                            $t("COLOR.IMPORT_PRODUCT_PAGE_LINK")
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-0 row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("COLOR.CUSTOMS_NR") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="customs_nr"
                                        name="customs_nr"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="color.customs_nr"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <!--end::Body-->
            </form>
            <!--end::Form-->
        </div>
        <div
            v-if="!add_new && !not_found"
            class="card card-custom mt-5 align-items-center"
        >
            <div class="card-header align-items-center py-5">
                <b-nav pills>
                    <b-nav-item @click="tab = 'SIZES'" :active="tab == 'SIZES'"
                        >{{ $t("SIZE.SIZES") }}
                    </b-nav-item>
                    <b-nav-item
                        @click="tab = 'COMPONENTS_AND_LAUNDRY'"
                        :active="tab == 'COMPONENTS_AND_LAUNDRY'"
                        >{{ $t("COLOR.COMPONENTS_AND_LAUNDRY_INSTRUCTIONS") }}
                    </b-nav-item>
                </b-nav>
            </div>
        </div>
        <div v-if="!add_new && !not_found" class="mt-5">
            <component v-bind:is="currentComponent"></component>
        </div>
    </div>
</template>

<script>
import {
    ADD_BREADCRUMB,
    SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module"
import ApiService from "@/core/services/api.service"
import Sizes from "@/view/pages/Sizes"
import ComponentsAndLaundry from "@/view/pages/ComponentsAndLaundry"
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger"
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap"
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core"
import KTUtil from "@/assets/js/components/util"
import Vue from "vue"

export default {
    name: "color",
    data() {
        return {
            add_new: false,
            edit_mode: false,
            not_found: false,
            tab: "SIZES",
            default_image: "media/images/placeholder.png",
            current_image: null,
            new_image: {},
            original_color: null,
            color: {
                image: "",
                name: "",
                code: "",
                weight: "",
                year: "",
                origin: "",
                url: "",
                customs_nr: "",
            },
        }
    },
    mounted() {
        if (this.$route.params.tab) {
            this.tab = this.$route.params.tab
        }
        if (this.$route.params.id == "new" && this.$route.params.product_id) {
            this.add_new = true
            this.edit_mode = true
            this.$store.dispatch(ADD_BREADCRUMB, [
                { title: this.$t("COLOR.NEW") },
            ])
        } else {
            ApiService.get("color/" + this.$route.params.id)
                .then((data) => {
                    this.original_color = data.data
                    this.color = JSON.parse(JSON.stringify(this.original_color))
                    this.current_image = this.color.image
                    if (data.headers.breadcrumbs) {
                        var breadcumbs = JSON.parse(
                            Buffer.from(
                                data.headers.breadcrumbs,
                                "base64"
                            ).toString("utf-8")
                        )
                        this.$store.dispatch(SET_BREADCRUMB, [
                            {
                                title: breadcumbs.product.name,
                                route: "/product/" + breadcumbs.product.id,
                            },
                            { title: data.data.name },
                        ])
                    }
                    if (this.$route.params.edit) {
                        this.edit_mode = true
                    }
                })
                .catch(() => {
                    this.not_found = true
                    this.$store.dispatch(SET_BREADCRUMB, [])
                })
        }

        this.fv = formValidation(KTUtil.getById("color_form"), {
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("COLOR.NAME"),
                            }),
                        },
                    },
                },
                /*
                    code: {
                        validators: {
                            digits: {
                                message: this.$t("VALIDATION.DIGITS", {
                                    name: this.$t("COLOR.CODE"),
                                }),
                            },
                        },
                    },
*/
                weight: {
                    validators: {
                        digits: {
                            message: this.$t("VALIDATION.DIGITS", {
                                name: this.$t("COLOR.WEIGHT"),
                            }),
                        },
                    },
                },
                year: {
                    validators: {
                        digits: {
                            message: this.$t("VALIDATION.DIGITS", {
                                name: this.$t("COLOR.YEAR"),
                            }),
                        },
                        stringLength: {
                            message: this.$t("VALIDATION.LENGTH_EXACT", {
                                name: this.$t("COLOR.YEAR"),
                                length: 4,
                            }),
                            min: 4,
                            max: 4,
                        },
                    },
                },
                url: {
                    validators: {
                        uri: {
                            message: this.$t("VALIDATION.WEB_URL", {
                                name: this.$t("COLOR.URL"),
                            }),
                            protocol: "http,https",
                            allowEmptyProtocol: true,
                        },
                    },
                },
                customs_nr: {
                    validators: {
                        digits: {
                            message: this.$t("VALIDATION.DIGITS", {
                                name: this.$t("COLOR.CUSTOMS_NR"),
                            }),
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        })
    },
    methods: {
        edit() {
            this.edit_mode = true
        },
        cancel() {
            if (this.add_new) {
                this.$router.push({
                    name: "product",
                    params: { id: this.$route.params.product_id },
                })
            } else {
                this.color = JSON.parse(JSON.stringify(this.original_color))
                this.fv.resetForm(true)
                this.current_image = this.color.image
                this.new_image = {}
                this.edit_mode = false
            }
        },
        save() {
            this.fv.validate().then((status) => {
                if (status == "Valid") {
                    KTUtil.getById("color_save").classList.add(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    )

                    if (this.add_new) {
                        ApiService.post(
                            "product/" +
                                this.$route.params.product_id +
                                "/color",
                            this.color
                        )
                            .then((data) => {
                                this.color.id = data.data.id
                            })
                            .then(() => this.saveImage(this.color.id))
                            .then(() => {
                                KTUtil.getById("color_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                                this.$router.push({
                                    name: "color",
                                    params: { id: this.color.id },
                                })
                            })
                            .catch(() => {
                                KTUtil.getById("color_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    } else {
                        this.saveImage(this.color.id)
                            .then(() =>
                                ApiService.put(
                                    "color/" + this.color.id,
                                    this.color
                                )
                            )
                            .then((data) => {
                                KTUtil.getById("color_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                                this.original_color = data.data
                                this.cancel()
                            })
                            .catch(() => {
                                KTUtil.getById("color_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    }
                }
            })
        },
        saveImage(id) {
            if (this.new_image.data) {
                return ApiService.put("color/" + id + "/image", this.new_image)
            } else if (!this.current_image && this.color.image) {
                return ApiService.delete("color/" + id + "/image")
            } else {
                return Promise.resolve(true)
            }
        },
        changedImage(event) {
            const file = event.target.files[0]
            const reader = new FileReader()
            reader.onload = (event) => {
                this.current_image = event.target.result
                this.new_image.data = event.target.result.replace(
                    /^data:image\/\w+;base64,/,
                    ""
                )
                this.new_image.mime_type = file.type
            }
            reader.readAsDataURL(file)
        },
        clearImage() {
            this.current_image = null
            this.new_image = {}
        },
        setProductPageURL() {
            if (this.edit_mode) {
                Vue.set(
                    this.color,
                    "url",
                    `https://product.xiphoo.com${process.env.VUE_APP_PRODUCT_PAGE_PATH}`
                )
            }
        },
        checkURL() {
            if (this.color.url) {
                return !this.color.url.includes("product.xiphoo.com")
            } else {
                return true
            }
        },
    },
    computed: {
        image() {
            return this.current_image == null
                ? this.default_image
                : this.current_image
        },
        currentComponent() {
            switch (this.tab) {
                case "COMPONENTS_AND_LAUNDRY":
                    return ComponentsAndLaundry
                case "SIZES":
                default:
                    return Sizes
            }
        },
    },
    components: {
        Sizes,
        ComponentsAndLaundry,
    },
}
</script>
