<template>
    <div>
        <b-alert :show="alert.show" :variant="alert.variant" fade>
      <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
        alert.text
      }}</span>
        </b-alert>
        <!--begin::Advance Table Widget 10-->
        <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("COMPONENT.COMPONENTS")
          }}</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
            $tc("COMPONENT.SHOW_COUNT", paginatedComponents.length, {
              total: components.length,
            })
          }}</span>
                </h3>
                <div class="card-toolbar">
                    <router-link
                            :to="{
              name: 'componentmaterials',
              params: { id: 'new', color_id: this.$route.params.id },
            }"
                    >
                        <b-button variant="primary">{{ $t("COMPONENT.NEW") }}</b-button>
                    </router-link>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="components.length">
                    <table
                            class="table table-head-custom table-vertical-center"
                            id="kt_advance_table_widget_4"
                    >
                        <thead>
                        <tr class="text-left">
                            <th class="pl-0" style="min-width: 140px">
                                {{ $t("COMPONENT.NAME") }}
                            </th>
                            <th style="min-width: 110px">
                                {{ $t("COMPONENT.MATERIALS") }}
                            </th>
                            <th class="pr-0 text-right" style="min-width: 120px">
                                {{ $t("ACTIONS") }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="component in paginatedComponents">
                            <tr v-bind:key="component.id">
                                <td class="pl-0">
                                    <router-link
                                            :to="{
                        name: 'componentmaterials',
                        params: { id: component.id },
                      }"
                                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                    >{{ component.name }}
                                    </router-link
                                    >
                                </td>
                                <td>
                                    <template v-for="(material, i) in component.materials">
                      <span
                              v-bind:key="i"
                              class="text-dark-75 font-weight-bold d-block font-size-lg"
                      >{{ material.amount + "% " + material.name }}</span
                      >
                                    </template>
                                </td>
                                <td class="pr-0 text-right">
                                    <router-link
                                            :to="{
                        name: 'componentmaterials',
                        params: { id: component.id, edit: true },
                      }"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                        <inline-svg
                                src="media/svg/icons/Communication/Write.svg"
                                :title="$t('EDIT')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </router-link>
                                    <a
                                            v-on:click="confirmDelete(component.id)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                                :title="$t('DELETE')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </a>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                    <b-pagination
                            v-if="components.length > pagination.per_page"
                            class="pt-5"
                            v-model="pagination.current_page"
                            :total-rows="components.length"
                            :per-page="pagination.per_page"
                            align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Advance Table Widget 10-->
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import Swal from "sweetalert2";

    export default {
        name: "components",
        data() {
            return {
                components: [],
                alert: {
                    show: false,
                    message: "",
                    variant: "",
                },
                pagination: {
                    current_page: 1,
                    per_page: 5,
                },
            };
        },
        mounted() {
            if (this.$route.params.id == "new") {
                return;
            }
            ApiService.get("color/" + this.$route.params.id + "/components")
                .then(({data}) => {
                    this.components = data;
                })
                .catch(() => {
                    //Just keep it empty
                });
        },
        methods: {
            confirmDelete: function (id) {
                const component_index = this.components.findIndex(
                    (component) => component.id === id
                );
                const component = this.components.find(
                    (component) => component.id === id
                );
                Swal.fire({
                    title: this.$t("COMPONENT.DELETE_TITLE", {name: component.name}),
                    text: this.$t("COMPONENT.DELETE_TEXT"),
                    icon: "error",
                    showCancelButton: true,
                    cancelButtonText: this.$t("CANCEL"),
                    confirmButtonText: this.$t("DELETE"),
                    buttonsStyling: false,
                    customClass: {
                        cancelButton: "btn btn-secondary",
                        confirmButton: "btn btn-danger",
                    },
                    heightAuto: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApiService.delete("component/" + id)
                            .then(() => {
                                this.components.splice(component_index, 1);
                                this.showAlert(
                                    this.$t("COMPONENT.DELETE_SUCCESS", {name: component.name}),
                                    "success"
                                );
                            })
                            .catch(() => {
                                this.showAlert(
                                    this.$t("COMPONENT.DELETE_FAILURE", {name: component.name}),
                                    "danger"
                                );
                            });
                    }
                });
            },
            showAlert(text, variant) {
                this.alert.text = text;
                this.alert.variant = variant;
                this.alert.show = true;
                setTimeout(() => (this.alert.show = false), 5000);
            },
        },
        computed: {
            paginatedComponents() {
                const start =
                    (this.pagination.current_page - 1) * this.pagination.per_page,
                    end = start + this.pagination.per_page;
                return this.components.slice(start, end);
            },
        },
    };
</script>
