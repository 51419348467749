<template>
    <div>
        <div class="row">
            <div class="col-lg-6">
                <Components></Components>
            </div>
            <div class="col-lg-6">
                <Laundry></Laundry>
            </div>
        </div>
    </div>
</template>

<script>
    import Components from "@/view/pages/Components";
    import Laundry from "@/view/pages/Laundry";

    export default {
        name: "componentsAndLaundry",
        components: {
            Components,
            Laundry,
        },
    };
</script>
