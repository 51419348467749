<template>
    <div>
        <!--begin::Card-->
        <div v-if="!not_found" class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">
                        {{ $t("LAUNDRY.TITLE") }}
                    </h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">{{
            edit_mode ? $t("EDIT") : $t("VIEW")
          }}</span>
                </div>
                <div v-if="edit_mode" class="card-toolbar">
                    <a
                            v-on:click="save()"
                            class="btn btn-primary px-15 mr-2"
                            id="laundry_save"
                    >{{ $t("SAVE") }}
                    </a>
                    <a v-on:click="cancel()" class="btn btn-secondary"
                    >{{ $t("CANCEL") }}
                    </a>
                </div>
                <div v-else class="card-toolbar">
                    <a v-on:click="edit()" class="btn btn-primary px-10"
                    >{{ $t("EDIT") }}
                    </a>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                    <form class="form" id="laundry_form">
                        <fieldset id="edit_wrapper" :disabled="!edit_mode">
                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("LAUNDRY.WASHING")
                                    }}</label>
                                <div class="col-lg-9 col-xl-9">
                                    <div class="input-group input-group-md input-group-solid">
                                        <select
                                                class="form-control form-control-md form-control-solid"
                                                v-model="laundry.washing"
                                        >
                                            <option value></option>
                                            <template v-for="washing_option in options.washing">
                                                <option
                                                        v-bind:key="washing_option.key"
                                                        :value="washing_option.key"
                                                >
                                                    {{ washing_option.text }}
                                                </option>
                                            </template>
                                        </select>
                                        <div class="input-group-append">
                      <span class="input-group-text">
                        <img
                                v-if="washingImage"
                                width="35px"
                                height="35px"
                                :src="washingImage"
                        />
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("LAUNDRY.BLEACHING")
                                    }}</label>
                                <div class="col-lg-9 col-xl-9">
                                    <div class="input-group input-group-md input-group-solid">
                                        <select
                                                class="form-control form-control-md form-control-solid"
                                                v-model="laundry.bleaching"
                                        >
                                            <option value></option>
                                            <template v-for="bleaching_option in options.bleaching">
                                                <option
                                                        v-bind:key="bleaching_option.key"
                                                        :value="bleaching_option.key"
                                                >
                                                    {{ bleaching_option.text }}
                                                </option>
                                            </template>
                                        </select>
                                        <div class="input-group-append">
                      <span class="input-group-text">
                        <img
                                v-if="bleachingImage"
                                width="35px"
                                height="35px"
                                :src="bleachingImage"
                        />
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("LAUNDRY.DRYING")
                                    }}</label>
                                <div class="col-lg-9 col-xl-9">
                                    <div class="input-group input-group-md input-group-solid">
                                        <select
                                                class="form-control form-control-md form-control-solid"
                                                v-model="laundry.drying"
                                        >
                                            <option value></option>
                                            <template v-for="drying_option in options.drying">
                                                <option
                                                        v-bind:key="drying_option.key"
                                                        :value="drying_option.key"
                                                >
                                                    {{ drying_option.text }}
                                                </option>
                                            </template>
                                        </select>
                                        <div class="input-group-append">
                      <span class="input-group-text">
                        <img
                                v-if="dryingImage"
                                width="35px"
                                height="35px"
                                :src="dryingImage"
                        />
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("LAUNDRY.IRONING")
                                    }}</label>
                                <div class="col-lg-9 col-xl-9">
                                    <div class="input-group input-group-md input-group-solid">
                                        <select
                                                class="form-control form-control-md form-control-solid"
                                                v-model="laundry.ironing"
                                        >
                                            <option value></option>
                                            <template v-for="ironing_option in options.ironing">
                                                <option
                                                        v-bind:key="ironing_option.key"
                                                        :value="ironing_option.key"
                                                >
                                                    {{ ironing_option.text }}
                                                </option>
                                            </template>
                                        </select>
                                        <div class="input-group-append">
                      <span class="input-group-text">
                        <img
                                v-if="ironingImage"
                                width="35px"
                                height="35px"
                                :src="ironingImage"
                        />
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-0 row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("LAUNDRY.PROFESSIONAL_CLEANING")
                                    }}</label>
                                <div class="col-lg-9 col-xl-9">
                                    <div class="input-group input-group-md input-group-solid">
                                        <select
                                                class="form-control form-control-md form-control-solid"
                                                v-model="laundry.professional_cleaning"
                                        >
                                            <option value></option>
                                            <template
                                                    v-for="professional_cleaning_option in options.professional_cleaning"
                                            >
                                                <option
                                                        v-bind:key="professional_cleaning_option.key"
                                                        :value="professional_cleaning_option.key"
                                                >
                                                    {{ professional_cleaning_option.text }}
                                                </option>
                                            </template>
                                        </select>
                                        <div class="input-group-append">
                      <span class="input-group-text">
                        <img
                                v-if="professionalCleaningImage"
                                width="35px"
                                height="35px"
                                :src="professionalCleaningImage"
                        />
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <!--end::Body-->
            </form>
            <!--end::Form-->
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import KTUtil from "@/assets/js/components/util";

    export default {
        name: "laundry",
        data() {
            return {
                edit_mode: false,
                not_found: false,
                options: {
                    washing: [],
                    bleaching: [],
                    drying: [],
                    ironing: [],
                    professional_cleaning: [],
                },
                laundry: {
                    washing: null,
                    bleaching: null,
                    drying: null,
                    ironing: null,
                    professional_cleaning: null,
                },
                original_laundry: {
                    washing: null,
                    bleaching: null,
                    drying: null,
                    ironing: null,
                    professional_cleaning: null,
                },
            };
        },
        mounted() {
            ApiService.get("options/laundry")
                .then((data) => {
                    this.options = data.data;
                })
                .then(() => ApiService.get("color/" + this.$route.params.id + "/laundry"))
                .then((data) => {
                    this.original_laundry = data.data;
                    this.laundry = JSON.parse(JSON.stringify(this.original_laundry));
                    if (this.$route.params.edit) {
                        this.edit_mode = true;
                    }
                })
                .catch(() => {
                    this.not_found = true;
                });
        },
        methods: {
            edit() {
                this.edit_mode = true;
            },
            cancel() {
                this.laundry = JSON.parse(JSON.stringify(this.original_laundry));
                this.edit_mode = false;
            },
            save() {
                KTUtil.getById("laundry_save").classList.add(
                    "spinner",
                    "spinner-light",
                    "spinner-right"
                );

                ApiService.put(
                    "color/" + this.$route.params.id + "/laundry",
                    this.laundry
                )
                    .then((data) => {
                        KTUtil.getById("laundry_save").classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        this.original_laundry = data.data;
                        this.cancel();
                    })
                    .catch(() => {
                        KTUtil.getById("laundry_save").classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                    });
            },
        },
        computed: {
            washingImage() {
                const option = this.options.washing.find(
                    (option) => option.key === this.laundry.washing
                );
                return option ? option.symbol : null;
            },
            bleachingImage() {
                const option = this.options.bleaching.find(
                    (option) => option.key === this.laundry.bleaching
                );
                return option ? option.symbol : null;
            },
            dryingImage() {
                const option = this.options.drying.find(
                    (option) => option.key === this.laundry.drying
                );
                return option ? option.symbol : null;
            },
            ironingImage() {
                const option = this.options.ironing.find(
                    (option) => option.key === this.laundry.ironing
                );
                return option ? option.symbol : null;
            },
            professionalCleaningImage() {
                const option = this.options.professional_cleaning.find(
                    (option) => option.key === this.laundry.professional_cleaning
                );
                return option ? option.symbol : null;
            },
        },
    };
</script>
